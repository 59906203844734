<style>
  .gradientBackground[c-phixSM_Registration_phixSM_Registration] {
    height: 1800px;
    width: 100%;
    background: rgb(0, 41, 78);
    background: linear-gradient(180deg, rgba(0, 41, 78, 1) 0%, rgba(0, 104, 180, 1) 100%);
    font-size: 25px;
    font-weight: 300;
  }
</style>

<main class="main">
  <div class="header">
    <a [href]="homeLink"><img src="assets/utimaco-logo.svg" style="height: 34px;" alt="Utimaco Logo" /></a>
    <div class="right-side-menu">
      <button mat-raised-button (click)="goToLoginPage()" color="accent" id="header-login-btn" i18n>
        Log-in
      </button>
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div class="content">
    <router-outlet />
  </div>
  <div class="footer">
    <a [href]="homeLink"><img src="assets/utimaco-logo.svg" style="height: 34px;" alt="Utimaco Logo" /></a>
    <a href="https://utimaco.com/utimaco-40-years-anniversary" target="_blank">
      <img src="assets/utimaco-claim.svg" style="height: 45px;" alt="40 years of Utimaco" /></a>
    <div style="max-width: 550px; min-width: 400px; font-weight: 300; color: rgb(60,60,60);">
      For over 40 years now, Utimaco products have been protecting people around the world against terrorism and
      organized crime and have secured private and sensitive data against theft or abuse
    </div>
    <div style="display: flex; min-width: 170px;">
      <a href="https://utimaco.com/current-topics/blog/utimaco-named-leader-in-hsm-market" target="_blank">
        <img src="assets/overall-leader-large.avif" height="83" [hspace]="15" alt="Utimaco Leader in HSM" />
        <img src="assets/top-implementer-large.avif" height="83" alt="Utimaco top implementer" />
      </a>
    </div>
  </div>

  <lib-taas-hidden-version-info [appVersion]="appVersion" [commonUiLibVersion]="commonUiLibVersion" />
  <lib-taas-toastr />
</main>