import { LOCALE_ID, Component, inject } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { countryCodeDetails } from './countryCodeDetails';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { merge } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../../environments/environment';
import { fieldConfig } from './fieldConfig';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomEmailValidatorAddOn } from './CustomEmailValidatorAddOn';
import { CustomPhoneValidatorAddOn } from './CustomPhoneValidatorAddOn';
import countryList from "../../assets/country_list.json";
import { ToastService } from 'utimaco-common-ui-angular';
import { Router } from '@angular/router';
import { matchFieldsValidator } from './MatchFieldsValidator';

@Component({
  selector: 'app-registration-form',
  standalone: true,
  imports: [HttpClientModule, FormsModule, ReactiveFormsModule, MatGridListModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatCheckboxModule, MatButtonModule],
  templateUrl: './registration-form.component.html',
  styleUrl: './registration-form.component.scss'
})
export class RegistrationFormComponent {
  readonly firstName = new FormControl('', Validators.required);
  readonly lastName = new FormControl('', Validators.required);
  readonly jobTitle = new FormControl('');
  readonly city = new FormControl('', Validators.required);
  readonly zip = new FormControl(''); //since some countries do not have ZIP codes, it cant be a required field
  readonly country = new FormControl('', Validators.required);
  readonly countryHidden = new FormControl('');

  readonly address = new FormControl('', Validators.required);
  readonly email = new FormControl('', [Validators.required, Validators.email, CustomEmailValidatorAddOn('|')]);
  readonly emailConfirmation = new FormControl('', [Validators.required, Validators.email, CustomEmailValidatorAddOn('|'), matchFieldsValidator(this.email)]);
  readonly phoneCountryCode = new FormControl('');
  readonly phoneNumber = new FormControl('', CustomPhoneValidatorAddOn());
  readonly phone = new FormControl('');
  readonly company = new FormControl('', Validators.required);
  readonly industry = new FormControl('', Validators.required);

  readonly termsAndConditions = new FormControl('', Validators.required);
  readonly dataProtectionNotice = new FormControl('', Validators.required);

  formErrorPresent: boolean = false;
  submissionError: boolean = false;
  submissionErrorDetails: string = "";
  formSubmissionInProgress: boolean = false;

  fieldsToCheckAndSend: fieldConfig[] = [
    {
      formControl: this.firstName,
      nameToSend: "FirstName"
    },
    {
      formControl: this.lastName,
      nameToSend: "LastName"
    },
    {
      formControl: this.jobTitle,
      nameToSend: "JobTitle"
    },
    {
      formControl: this.city,
      nameToSend: "City"
    },
    {
      formControl: this.zip,
      nameToSend: "ZIP"
    },
    {
      formControl: this.country,
      nameToSend: "CountryCode"
    },
    {
      formControl: this.address,
      nameToSend: "Street"
    },
    {
      formControl: this.email,
      nameToSend: "Email"
    },
    {
      formControl: this.emailConfirmation,
      nameToSend: ""
    },
    {
      formControl: this.phoneCountryCode,
      nameToSend: ""
    },
    {
      formControl: this.phoneNumber,
      nameToSend: ""
    },
    {
      formControl: this.phone,
      nameToSend: "Phone"
    },
    {
      formControl: this.company,
      nameToSend: "Company"
    },
    {
      formControl: this.industry,
      nameToSend: "Industry"
    },
    {
      formControl: this.termsAndConditions,
      nameToSend: "TermsAndConditions"
    },
    {
      formControl: this.dataProtectionNotice,
      nameToSend: "DataProtectionNotice"
    }
  ]

  toastService: ToastService = inject(ToastService);
  public activeLocale: string = inject(LOCALE_ID);
  private router: Router = inject(Router);
  private http: HttpClient = inject(HttpClient);

  constructor() {
    merge(
      this.firstName.statusChanges, this.firstName.valueChanges,
      this.jobTitle.statusChanges,
      this.lastName.statusChanges, this.lastName.valueChanges,
      this.city.statusChanges, this.city.valueChanges,
      this.zip.statusChanges, this.zip.valueChanges,
      this.country.statusChanges, this.country.valueChanges,
      this.address.statusChanges, this.address.valueChanges,
      this.email.statusChanges, this.email.valueChanges,
      this.emailConfirmation.statusChanges, this.emailConfirmation.valueChanges,
      this.phoneCountryCode.statusChanges, this.phoneCountryCode.valueChanges,
      this.phoneNumber.statusChanges, this.phoneNumber.valueChanges,
      this.company.statusChanges, this.company.valueChanges,
      this.industry.statusChanges, this.industry.valueChanges,
      this.termsAndConditions.statusChanges, this.termsAndConditions.valueChanges,
      this.dataProtectionNotice.statusChanges, this.dataProtectionNotice.valueChanges
    ).pipe(takeUntilDestroyed()).subscribe(() => this.updateFormErrorStatus());
  }

  goToLoginPage() {
    window.open(environment.loginLink, '_blank');
  }

  updateFormErrorStatus(hightlightErrors = false) {
    // filter all fields to check for the ones where validation fails
    this.formErrorPresent = this.fieldsToCheckAndSend.filter(fieldConfig => {
      return !fieldConfig.formControl.valid
    }).length > 0;
    // updates in primary email address field do not trigger the confirmation check, so need to set the state manually
    if(this.email.getRawValue() != this.emailConfirmation.getRawValue()){
      this.emailConfirmation.setErrors({"match": true}, {emitEvent: false});
    } else {
      this.emailConfirmation.setErrors(null, {emitEvent: false});
    }
    if (hightlightErrors) {
      this.fieldsToCheckAndSend.forEach(fieldConfig => fieldConfig.formControl.markAsTouched());
    }
  }

  onSubmit() {
    this.updateFormErrorStatus();
    if (!this.formErrorPresent) {
      this.phone.setValue("+" + this.phoneCountryCode.getRawValue() + this.phoneNumber.getRawValue())
      this.countryHidden.setValue(this.country.getRawValue());
      this.submissionErrorDetails = "";

      const dataToSend = this.fieldsToCheckAndSend.reduce<Record<string, string>>((out, obj) => {
        if (obj.nameToSend != "") {
          out[obj.nameToSend] = obj.formControl.getRawValue();
        }
        return out;
      }, {});

      this.formSubmissionInProgress = true;
      //TODO: show loading spinner dialog
      this.http.post(environment.formHandlerUrl, dataToSend).subscribe({
        next: data => {
          this.formSubmissionInProgress = false;
          //TODO: hide loading spinner dialog
          console.log(data);
          this.router.navigateByUrl("/success");
        },
        error: errorResponse => {
          this.formSubmissionInProgress = false;
          //TODO: hide loading spinner dialog
          this.submissionError = true;
          
          window.scrollTo({ top: 0 });
          const errorTitle = $localize`Error while trying to submit registration`;
          let errorText: string = "";
          if (errorResponse.error && errorResponse.error.data) {
            errorText = Object.values(errorResponse.error.data).map(value => String(value)).join("\n");
          }
          if(errorResponse.error.tid){
            errorText += "\ncode: " + errorResponse.error.tid;
            this.submissionErrorDetails = "Code: " + errorResponse.error.code + "<br>ID: " + errorResponse.error.tid;
          }
          this.toastService.error(errorTitle, errorText);
        }
      })
    }
  }

  industries: string[] = [
    "Aerospace & Defense",
    "Automotive & Transportation",
    "Banking & Financial Services",
    "Cloud Services",
    "Energy & Utilities",
    "Government",
    "Healthcare,Pharma & Biotech",
    "Information Technology",
    "Legal & Consulting",
    "Lottery & Gaming",
    "Manufacturing",
    "Media & Entertainment",
    "Retail",
    "Semiconductors",
    "Telecommunications",
    "Other"
  ]

  //TODO: use https://www.npmjs.com/package/dialcodes and https://www.npmjs.com/package/localized-countries
  countryCodeList: countryCodeDetails[] = countryList;

  getLocalCountryName(countryCodeObject: countryCodeDetails) {
    switch (this.activeLocale) {
      case 'de':
        if (countryCodeObject.displayName_de) {
          return countryCodeObject.displayName_de;
        }
        return countryCodeObject.displayName;
      default:
        return countryCodeObject.displayName;
    }
  }
}
